/* Header Background Part Start */
.audit_report_section .header_background {
  background-color: #a6d9cf72;
  height: 300px;
}

.audit_report_section .wrapper {
  display: grid;
}

.audit_report_section .wrapper h2 {
  padding-top: 120px;
  font-size: 50px;
}

.audit_report_section .wrapper .social_share_button {
  justify-self: end;
  display: flex;
  gap: 12px;
}
/* Header Background Part End */

.audit_report_section .audit_report_wrapper {
  margin: 40px 0px 70px 0px;
}

.audit_report_section .audit_report_wrapper h2 {
  font-size: 30px;
}

.audit_report_section .audit_report_wrapper .content_wrapper {
  display: grid;
}

.audit_report_section .audit_report_wrapper .content_wrapper span {
  font-size: 19px;
  background: #2dae9f;
  color: white;
  padding: 10px;
  margin-top: 20px;
  width: 30%;
  text-align: center;
  border-radius: 3px;
  cursor: pointer;
}
