/* Header Background Part Start */
.office_circular_section .header_background {
  background-color: #a6d9cf72;
  height: 300px;
}

.office_circular_section .wrapper {
  display: grid;
}

.office_circular_section .wrapper h2 {
  padding-top: 120px;
  font-size: 50px;
}

.office_circular_section .wrapper .social_share_button {
  justify-self: end;
  display: flex;
  gap: 12px;
}
/* Header Background Part End */

.office_circular_section .office_circular_wrapper {
  margin: 40px 0px 70px 0px;
}

.office_circular_section .office_circular_wrapper .content_wrapper h2 {
  font-size: 40px;
  font-weight: 600;
  text-align: center;
  margin: 40px 0px;
  padding: 10px 0px;
}

.office_circular_section .office_circular_wrapper .content_wrapper p {
  font-size: 20px;
  font-weight: 500;
  margin: 40px 0px 10px 0px;
}

.office_circular_section .office_circular_wrapper .content_wrapper .notice {
  width: 80%;
  margin: 0 auto;
}

.office_circular_section .office_circular_wrapper .content_wrapper .notice img {
  width: 100%;
  box-shadow: 0px 2px 12px -6px #909090;
}

@media only screen and (max-width: 1030px) {
  .office_circular_section .office_circular_wrapper .content_wrapper .notice {
    width: 100%;
  }
}
