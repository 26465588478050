/* Header Background Part Start */
.management_report_section .header_background {
  background-color: #a6d9cf72;
  height: 300px;
}

.management_report_section .wrapper {
  display: grid;
}

.management_report_section .wrapper h2 {
  padding-top: 120px;
  font-size: 50px;
}

.management_report_section .wrapper .social_share_button {
  justify-self: end;
  display: flex;
  gap: 12px;
}
/* Header Background Part End */

.management_report_section .management_report_wrapper {
  margin: 40px 0px 70px 0px;
}

.management_report_section .management_report_wrapper h2 {
  font-size: 30px;
}
