.complete_project_section .header_background {
  background-color: #a6d9cf72;
  height: 300px;
}

.complete_project_section .wrapper {
  display: grid;
}

.complete_project_section .wrapper h2 {
  padding-top: 120px;
  font-size: 50px;
}

.complete_project_section .wrapper .social_share_button {
  justify-self: end;
  display: flex;
  gap: 12px;
}
/* Header Background Part End */

.complete_project_section h3 {
  margin-bottom: 40px;
  text-align: center;
  font-size: 30px;
  font-weight: 500;
  margin-top: 60px;
  text-transform: uppercase;
}

.complete_project_section {
  background: #f6f6f6;
  padding: 0px 0px 70px 0px;
}

.complete_project_section .table_wrapper table thead tr th,
.complete_project_section .table_wrapper table tbody tr td {
  padding: 15px;
  border: 1px solid #e8e8e8;
  background: white;
  box-shadow: 0px 4px 6px -6px #c5c5c5;
}

/* Media Query */
@media only screen and (max-width: 1030px) {
  .complete_project_section h2 {
    font-size: 23px;
  }
}

@media only screen and (max-width: 800px) {
  .complete_project_section .header_background .social_share_button {
    margin-top: 20px;
  }

  .complete_project_section .wrapper h2 {
    font-size: 30px;
  }
}
