.social_welfare_section .header_background {
  background-color: #a6d9cf72;
  height: 300px;
}

.social_welfare_section .wrapper {
  display: grid;
}

.social_welfare_section .wrapper h2 {
  padding-top: 120px;
  font-size: 50px;
}

.social_welfare_section .wrapper .social_share_button {
  justify-self: end;
  display: flex;
  gap: 12px;
}

.social_welfare_section .content_wrapper {
  padding: 70px 0px;
}

.social_welfare_section img {
  background: white;
  box-shadow: 0px 0px 7px 2px #e6e6e6;
  width: 80%;
  margin: 0 auto;
}

/* Media Query */
@media only screen and (max-width: 1030px) {
  .social_welfare_section h2 {
    font-size: 25px;
  }
}

@media only screen and (max-width: 800px) {
  .social_welfare_section .header_background .social_share_button {
    margin-top: 20px;
  }

  .social_welfare_section .wrapper h2 {
    font-size: 30px;
    padding-top: 100px;
  }
}
