.moto_and_values_section {
  background-color: white;
}

.moto_and_values_section .header_background {
  background-color: #a6d9cf72;
  height: 300px;
}

.moto_and_values_section .wrapper {
  display: grid;
}

.moto_and_values_section .wrapper h2 {
  padding-top: 120px;
  font-size: 50px;
}

.moto_and_values_section .wrapper .social_share_button {
  justify-self: end;
  display: flex;
  gap: 12px;
}
/* Header Background Part End */

.moto_and_values_section .moto_section {
  padding: 40px 0px;
}

.moto_and_values_section .moto {
  display: grid;
  grid-template-columns: 48% auto 40%;
  grid-gap: 20px;
  padding: 40px;
  align-items: center;
}

.moto_and_values_section .moto .moto_left {
  text-align: center;
  border: 1px solid gainsboro;
  border-radius: 3px;
}

.moto_and_values_section .moto .moto_left h3 {
  font-size: 27px;
  font-weight: 500;
  padding: 30px;
}

.moto_and_values_section .moto .moto_left .holding_hand {
  font-size: 27px;
  color: #1b9d86;
}

.moto_and_values_section .moto .moto_left p {
  text-align: center;
  font-size: 17px;
  font-weight: 600;
  padding: 30px;
}

.moto_and_values_section .moto .moto_right {
  width: 90%;
  margin: 0 auto;
  justify-self: center;
  overflow: hidden;
  border-radius: 5px;
}

.moto_and_values_section .moto .moto_right img {
  width: 100%;
  border-radius: 3px;
  cursor: pointer;
  transition: all 0.6s ease;
}

.moto_and_values_section .moto .moto_right img:hover {
  transform: scale(1.2);
}

/* Middle Part Start */
.moto_and_values_section .moto .middle_arrow {
  text-align: center;
  font-size: 25px;
  color: #1b9d86;
}
.moto_and_values_section .moto .middle_arrow .downArrow_for_responsive {
  opacity: 0;
}

/* Values Part Start */
.moto_and_values_section .values_section {
  background-color: #f7f7f7;
  padding: 20px 0px 70px 0px;
}

.moto_and_values_section .values h3 {
  text-align: center;
  padding-top: 40px;
  font-size: 25px;
  text-transform: uppercase;
  font-weight: 600;
}

.moto_and_values_section .values .values_wrapper {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 50px;
  padding: 40px;
  align-items: center;
}

.moto_and_values_section .values .values_wrapper .values_divide {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 20px;
  /* padding: 40px; */
  align-items: center;
}

.moto_and_values_section .values .values_wrapper .image {
  width: 100%;
  height: 100%;
  background: #f2f2f2;
  border: 1px solid gainsboro;
  overflow: hidden;
}

.moto_and_values_section .values .values_wrapper img {
  width: 100%;
  height: 100%;
  cursor: pointer;
  transition: all 0.6s ease;
}

.moto_and_values_section .values .values_wrapper img:hover {
  transform: scale(1.2);
}

.moto_and_values_section .values .values_wrapper .single_values {
  display: flex;
  align-items: center;
  gap: 20px;
  margin-top: 10px;
}

.moto_and_values_section .values .values_wrapper .single_values .arrow_color {
  color: #9b5229;
}

.moto_and_values_section .values .values_wrapper .single_values p {
  text-align: left;
  color: #9b5229;
  font-size: 16px;
}

/* Media Query */
@media only screen and (max-width: 1030px) {
  .moto_and_values_section .moto,
  .moto_and_values_section .values .values_wrapper {
    grid-template-columns: repeat(1, 1fr);
  }

  .moto_and_values_section h2 {
    font-size: 25px;
  }

  .moto_and_values_section .moto .middle_arrow .downArrow_for_responsive {
    opacity: 1;
  }

  .moto_and_values_section .moto .middle_arrow .rightArrow_for_web {
    opacity: 0;
  }
}

@media only screen and (max-width: 800px) {
  .moto_and_values_section .header_background .social_share_button {
    margin-top: 20px;
  }

  .moto_and_values_section .wrapper h2 {
    padding-top: 100px;
    font-size: 30px;
  }
}
