.donation_section {
  margin-top: 60px;
}

.donation_section h2 {
  font-size: 30px;
  text-transform: uppercase;
  font-weight: 500;
  text-align: center;
  margin-bottom: 60px;
  color: #1b9d86;
}

.donation_section .content_wrapper .bank_information {
  width: 50%;
  height: 300px;
  margin: 0 auto;
  box-shadow: 0px 0px 7px 2px #e6e6e6;
  padding: 20px;
}

.donation_section .content_wrapper .bank_information h4 {
  font-size: 22px;
  text-align: left;
  margin: 25px 20px;
  font-weight: 500;
}

.donation_section .content_wrapper .bank_information p {
  font-size: 17px;
  margin: 10px 20px;
}

.donation_section .content_wrapper .bKash_information {
  width: 50%;
  height: 300px;
  margin: 50px auto;
  box-shadow: 0px 0px 7px 2px #e6e6e6;
  padding: 20px;
}

.donation_section .content_wrapper .bKash_information h4 {
  font-size: 22px;
  text-align: center;
  margin-bottom: 25px;
  font-weight: 500;
}

.donation_section .content_wrapper .bKash_information p {
  font-size: 17px;
  margin: 10px 0px;
  text-align: center;
}

/* Media Query */
@media only screen and (max-width: 1030px) {
  .donation_section .content_wrapper {
    grid-template-columns: repeat(1, 1fr);
  }

  .donation_section h2 {
    font-size: 25px;
  }
}

@media only screen and (max-width: 800px) {
  .donation_section .content_wrapper .bank_information,
  .donation_section .content_wrapper .bKash_information {
    width: 100%;
  }

  .donation_section .content_wrapper .bank_information h4,
  .donation_section .content_wrapper .bKash_information h4 {
    font-size: 20px;
    text-align: center;
  }

  .donation_section .content_wrapper .bank_information p,
  .donation_section .content_wrapper .bKash_information p {
    font-size: 13px;
    text-align: center;
  }
}
