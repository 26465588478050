.background_section .header_image {
  background-image: url("../../images/office-image.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  height: 600px;
}

.background_section h2 {
  text-transform: uppercase;
  font-weight: 500;
  font-size: 30px;
  padding-bottom: 20px;
  letter-spacing: 3px;
  margin: 40px 0px;
  text-align: center;
  border-bottom: 1px solid gainsboro;
  background-color: rgba(255, 255, 255, 0.399);
}

/* For Social Share Icon Start */
.background_section .social_share_button {
  display: flex;
  justify-content: right;
  align-items: center;
  gap: 10px;
  margin-top: 25px;
}
/* For Social Share Icon End */

.background_section .content_wrapper img {
  width: 80%;
  height: 80vh;
  margin-bottom: 20px;
  cursor: pointer;
  margin: 0 auto;
}

.background_section .content_wrapper .content {
  padding: 20px;
  margin: 40px 0px 70px 0px;
}

.background_section .content_wrapper p {
  text-align: left;
  line-height: 25px;
}

.background_section .content_wrapper .for_margin {
  margin: 15px 0px;
}

/* Media Query */
@media only screen and (max-width: 1030px) {
  .background_section h2 {
    font-size: 25px;
  }
}
