.allBlogs_section {
  background: white;
  padding-bottom: 60px;
}

.allBlogs_section h2 {
  padding: 60px 0px;
  font-size: 40px;
  font-weight: 600;
  text-align: center;
  text-transform: uppercase;
}

.allBlogs_section .allBlogs_wrapper {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 40px;
  align-items: center;
}

.allBlogs_section .allBlogs_wrapper .single_wrapper .image_wrapper {
  width: 100%;
  overflow: hidden;
}

.allBlogs_section .allBlogs_wrapper .single_wrapper:hover .image_title a {
  text-decoration: underline;
}

.allBlogs_section .allBlogs_wrapper .single_wrapper .image_wrapper img {
  width: 100%;
  height: 240px;
  cursor: pointer;
  transition: all 0.8s ease;
}

.allBlogs_section .allBlogs_wrapper .single_wrapper .image_wrapper img:hover {
  transform: scale(1.1);
}

.allBlogs_section .allBlogs_wrapper .single_wrapper .image_title {
  /* margin-left: 20px; */
  padding: 0px 10px 0px 10px;
}

.allBlogs_section .allBlogs_wrapper .single_wrapper .image_title p {
  color: rgb(29, 29, 29);
  margin: 10px 0px;
  font-weight: 600;
  font-size: 13px;
  text-transform: uppercase;
}

.allBlogs_section .allBlogs_wrapper .single_wrapper .image_title a {
  font-size: 17px;
  color: black;
  font-weight: 600;
  cursor: pointer;
  line-height: 20px;
}

.allBlogs_section .border {
  margin-top: 75px;
  width: 100%;
  height: 6px;
  background: green;
}

/* Media Query */
@media only screen and (max-width: 1030px) {
  .allBlogs_section {
    grid-template-columns: repeat(2, 1fr);
  }

  .allBlogs_section h2 {
    font-size: 25px;
  }
}

@media only screen and (max-width: 800px) {
  .allBlogs_section {
    grid-template-columns: repeat(1, 1fr);
  }

  .allBlogs_section .single_item_wrapper {
    margin-bottom: 30px;
  }
}
