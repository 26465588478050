.executive_council_section .header_background {
  background-color: #a6d9cf72;
  height: 300px;
}

.executive_council_section .wrapper {
  display: grid;
}

.executive_council_section .wrapper h2 {
  padding-top: 120px;
  font-size: 50px;
}

.executive_council_section .wrapper .social_share_button {
  justify-self: end;
  display: flex;
  gap: 12px;
}
/* Header Background Part End */

.executive_council_section .title_heading {
  margin-top: 50px;
}

.executive_council_section .top_image {
  margin-top: 60px;
}

.executive_council_section .top_image .single_content_wrapper {
  width: 30%;
  margin: 0 auto;
}

.executive_council_section .top_image img {
  width: 60%;
  border: 1px solid gainsboro;
  border-radius: 10px;
  margin: 0 auto;
  padding: 10px;
  background: linear-gradient(45deg, #009f52, transparent);
}

.executive_council_section .top_image .single_content_wrapper .title_wrapper {
  /* background: #1b9d8680; */
  padding: 5px 0px;
}

.executive_council_section
  .top_image
  .single_content_wrapper
  .title_wrapper
  h4 {
  text-align: center;
  font-weight: 500;
  color: black;
}

.executive_council_section .top_image .single_content_wrapper .title_wrapper p {
  text-align: center;
  color: black;
}

.executive_council_section .content_wrapper {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 50px;
  margin: 70px 0px;
}

.executive_council_section .content_wrapper img {
  width: 60%;
  border: 1px solid gainsboro;
  border-radius: 10px;
  margin: 0 auto;
  padding: 10px;
  background: linear-gradient(45deg, #009f52, transparent);
}

.executive_council_section
  .content_wrapper
  .single_content_wrapper
  .title_wrapper {
  /* background: #1b9d8680; */
  padding: 5px 0px;
}

.executive_council_section
  .content_wrapper
  .single_content_wrapper
  .title_wrapper
  h4 {
  text-align: center;
  font-weight: 500;
}

.executive_council_section
  .content_wrapper
  .single_content_wrapper
  .title_wrapper
  p {
  text-align: center;
  color: black;
}

/* Media Query */
@media only screen and (max-width: 1030px) {
  .executive_council_section .content_wrapper {
    grid-template-columns: repeat(2, 1fr);
  }

  .executive_council_section h2 {
    font-size: 25px;
  }

  .executive_council_section .top_image .single_content_wrapper {
    width: 50%;
  }
}

@media only screen and (max-width: 800px) {
  .executive_council_section .content_wrapper {
    grid-template-columns: repeat(1, 1fr);
  }

  .executive_council_section .top_image .single_content_wrapper {
    width: 100%;
  }

  .executive_council_section .header_background .social_share_button {
    margin-top: 20px;
  }

  .executive_council_section .wrapper h2 {
    font-size: 30px;
  }
}
