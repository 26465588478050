.header_image_section {
  position: relative;
}

.header_image_section img {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  width: 100%;
  /* height: 100vh; */
}

.header_image_section .content h3 {
  text-align: center;
  color: white;
  font-size: 50px;
}

.header_image_section .content p {
  background: #00000062;
    text-align: center;
    color: white;
    font-size: 30px;
    padding: 12px 40px;
    border-radius: 3px;
}
