.gallery_section {
  margin-top: 40px;
}

.gallery_section h2 {
  font-size: 30px;
  font-weight: 500;
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 40px;
}

.gallery_section .content_wrapper {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 70px;
  margin-bottom: 70px;
}

.gallery_section .content_wrapper .single_image_wrapper {
  position: relative;
  display: inline-block;
  overflow: hidden;
  max-width: 100%;
  max-height: 100%;
  box-shadow: 0px 0px 6px 2px #dcdcdc;
}

.gallery_section .content_wrapper .single_image_wrapper img {
  max-width: 100%;
  cursor: pointer;
}

.gallery_section .content_wrapper .single_image_wrapper .caption {
  position: absolute;
  left: 0;
  bottom: 0;
  color: white;
  font-size: 20px;
  width: 100%;
  height: 70px;
  background-color: rgba(0, 0, 0, 0.688);
  opacity: 1;
  transition: opacity 0.5s ease;
  overflow: hidden;
}

.gallery_section .content_wrapper .single_image_wrapper .caption span {
  font-size: 17px;
  display: grid;
  text-align: center;
  padding: 0px 30px;
  padding-top: 15px;
}

/* Media Query */
@media only screen and (max-width: 1030px) {
  .gallery_section h2 {
    font-size: 25px;
  }
}

@media only screen and (max-width: 800px) {
  .gallery_section .content_wrapper {
    grid-template-columns: repeat(1, 1fr);
  }
}
