.comment_section {
  padding: 70px 0px;
}

.comment_section h2 {
  font-size: 30px;
  font-weight: 500;
  margin-bottom: 20px;
  color: #1b9d86;
}

.comment_section .comment_wrapper .name_email_wrapper {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  align-items: center;
}

.comment_section .comment_wrapper .button {
  background: #1b9d86;
  color: white;
  padding: 10px 20px;
  border-radius: 3px;
  transition: all 0.5s ease;
}

.comment_section .comment_wrapper .button:hover {
  background: #0f5649;
}

@media only screen and (max-width: 1030px) {
  .comment_section .comment_wrapper .name_email_wrapper {
    grid-template-columns: repeat(1, 1fr);
  }
}
