.about_section {
  margin-top: 80px;
}

.about_section h2 {
  font-size: 30px;
  text-transform: uppercase;
  font-weight: 600;
  text-align: center;
  margin-bottom: 20px;
}

.about_section p {
  font-size: 18px;
  text-align: left;
}

.about_section .first_paragraph {
  padding: 20px;
}

.about_section .second_paragraph {
  /* margin-top: 20px; */
  padding: 20px;
}

/* Media Query */
@media only screen and (max-width: 1030px) {
  .about_section h2 {
    font-size: 25px;
  }
}
