.project_completion_report_section .header_background {
  background-color: #a6d9cf72;
  height: 300px;
}

.project_completion_report_section .wrapper {
  display: grid;
}

.project_completion_report_section .wrapper h2 {
  padding-top: 120px;
  font-size: 50px;
}

.project_completion_report_section .wrapper .social_share_button {
  justify-self: end;
  display: flex;
  gap: 12px;
}
/* Header Background Part End */

.project_completion_report_section {
  margin-bottom: 60px;
}

.project_completion_report_section .project_completion_report_wrapper h3 {
  font-size: 35px;
  font-weight: 500;
  text-align: center;
  text-transform: uppercase;
  margin: 40px 0px;
}

.project_completion_report_section .project_completion_report_wrapper .report {
  display: flex;
  gap: 40px;
  align-items: center;
  font-size: 19px;
}

/* This part is for only hover action Start */
.project_completion_report_section
  .project_completion_report_wrapper
  .content_wrapper
  .report
  a {
  overflow: hidden;
  position: relative;
  display: inline-block;
}

.project_completion_report_section
  .project_completion_report_wrapper
  .content_wrapper
  .report
  a::before,
.project_completion_report_section
  .project_completion_report_wrapper
  .content_wrapper
  .report
  a::after {
  content: "";
  position: absolute;
  width: 100%;
  left: 0;
}
.project_completion_report_section
  .project_completion_report_wrapper
  .content_wrapper
  .report
  a::before {
  background-color: #009f52;
  height: 2px;
  bottom: 0;
  transform-origin: 100% 50%;
  transform: scaleX(0);
  transition: transform 0.5s cubic-bezier(0.76, 0, 0.24, 1);
}
.project_completion_report_section
  .project_completion_report_wrapper
  .content_wrapper
  .report
  a::after {
  content: attr(data-replace);
  height: 100%;
  top: 0;
  transform-origin: 100% 50%;
  transform: translate3d(200%, 0, 0);
  transition: transform 0.5s cubic-bezier(0.76, 0, 0.24, 1);
  color: #009f52;
}

.project_completion_report_section
  .project_completion_report_wrapper
  .content_wrapper
  .report
  a:hover::before {
  transform-origin: 0% 50%;
  transform: scaleX(1);
}
.project_completion_report_section
  .project_completion_report_wrapper
  .content_wrapper
  .report
  a:hover::after {
  transform: translate3d(0, 0, 0);
}

.project_completion_report_section
  .project_completion_report_wrapper
  .content_wrapper
  .report
  a
  span {
  display: inline-block;
  font-weight: 600;
  transition: transform 0.5s cubic-bezier(0.76, 0, 0.24, 1);
}

.project_completion_report_section
  .project_completion_report_wrapper
  .content_wrapper
  .report
  a:hover
  span {
  transform: translate3d(-200%, 0, 0);
}
/* This part is for only hover action End */

/* Media Query */
@media only screen and (max-width: 1030px) {
  .project_completion_report_section h2 {
    font-size: 25px;
  }
}

@media only screen and (max-width: 800px) {
  .project_completion_report_section .header_background .social_share_button {
    margin-top: 20px;
  }

  .project_completion_report_section .wrapper h2 {
    font-size: 30px;
  }
}
