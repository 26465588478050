.exchange_meeting_section {
  padding-top: 80px;
  background-color: white;
}

.exchange_meeting_section .blog_title h6 {
  font-weight: 500;
  text-transform: uppercase;
  font-size: 13px;
}

.exchange_meeting_section .blog_title h2 {
  font-weight: 600;
  font-size: 50px;
  margin-top: 60px;
  font-family: "Be Vietnam Pro", sans-serif;
}

.exchange_meeting_section .blog_title h3 {
  font-weight: 600;
  font-size: 15px;
  margin: 20px 0px;
}

.exchange_meeting_section .image_part {
  padding: 10px;
}

.exchange_meeting_section .image_part .header_image {
  background-image: url("../../images/Blogs-photo//07.02.2024/exchange-meeting.jpeg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  height: 600px;
}

/* For Social Share Icon Start */
.exchange_meeting_section .social_share_wrapper {
  margin: 30px 0px;
}

.exchange_meeting_section .social_share_wrapper .social_share_button {
  display: flex;
  justify-content: right;
  align-items: center;
  gap: 10px;
  margin-top: 5px;
}
/* For Social Share Icon End */

.exchange_meeting_section .image_part p {
  max-width: 1200px;
  margin: 30px auto;
  padding: 0px 50px 30px 50px;
  font-size: 17px;
  color: rgb(79, 79, 79);
  border-bottom: 1px solid black;
}

.exchange_meeting_section .content_wrapper p {
  font-size: 18px;
}

.exchange_meeting_section .content_wrapper button {
  margin: 50px 0px 30px 0px;
}

/* This part is for only hover action Start */
.exchange_meeting_section .content_wrapper button a {
  overflow: hidden;
  position: relative;
  display: inline-block;
  font-size: 18px;
  font-weight: 600;
}

.exchange_meeting_section .content_wrapper button a::before,
.exchange_meeting_section .content_wrapper button a::after {
  content: "";
  position: absolute;
  width: 100%;
  left: 0;
}
.exchange_meeting_section .content_wrapper button a::before {
  background-color: #009f52;
  height: 2px;
  bottom: 0;
  transform-origin: 100% 50%;
  transform: scaleX(0);
  transition: transform 0.5s cubic-bezier(0.76, 0, 0.24, 1);
}
.exchange_meeting_section .content_wrapper button a::after {
  content: attr(data-replace);
  height: 100%;
  top: 0;
  transform-origin: 100% 50%;
  transform: translate3d(200%, 0, 0);
  transition: transform 0.5s cubic-bezier(0.76, 0, 0.24, 1);
  color: #009f52;
}

.exchange_meeting_section .content_wrapper button a:hover::before {
  transform-origin: 0% 50%;
  transform: scaleX(1);
}
.exchange_meeting_section .content_wrapper button a:hover::after {
  transform: translate3d(0, 0, 0);
}

.exchange_meeting_section .content_wrapper button a span {
  display: inline-block;
  font-weight: 600;
  transition: transform 0.5s cubic-bezier(0.76, 0, 0.24, 1);
}

.exchange_meeting_section .content_wrapper button a:hover span {
  transform: translate3d(-200%, 0, 0);
}
/* This part is for only hover action End */

@media only screen and (max-width: 800px) {
  .exchange_meeting_section .blog_title h2 {
    font-size: 30px;
  }
}
