/* Header Background Part Start */
.job_circular_section .header_background {
  background-color: #a6d9cf72;
  height: 300px;
}

.job_circular_section .wrapper {
  display: grid;
}

.job_circular_section .wrapper h2 {
  padding-top: 120px;
  font-size: 50px;
}

.job_circular_section .wrapper .social_share_button {
  justify-self: end;
  display: flex;
  gap: 12px;
}
/* Header Background Part End */

.job_circular_section .job_circular_wrapper {
  margin: 40px 0px 70px 0px;
}

.job_circular_section .job_circular_wrapper h2 {
  font-size: 30px;
}

.job_circular_section .job_circular_wrapper h2 {
  text-align: center;
  margin: 30px 0px;
  font-weight: 500;
}

.job_circular_section .job_circular_wrapper img {
  width: 80%;
  margin: 0 auto;
  box-shadow: 0px 0px 8px 1px #a8a4a4;
}